<template>
  <div>
    <div class="background-image-section">
      <div
        class="d-flex justify-content-center background-color-section"
        style="background-image: url(img/bg.jpg)"
      >
        <div class="login-card ma-auto">
          <div class="card-head row justify-content-center mb-3">
            <!-- <img src="../assets/images/logo2 2.png" class="img-fluid" /> -->
          </div>
          <div id="card" class="pb-2">
            <div id="card-content">
              <div id="card-title">
                <!-- <img src="../assets/images/Group 7.png" class="img-fluid" /> -->
                <h5 class="font-weight-bold">CRM</h5>
              </div>

              <v-form @submit.prevent="onSubmit" ref="form" lazy-validation>
                <v-container>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field
                        label="Email"
                        placeholder="Your Email"
                        v-model="data.email"
                        type="text"
                        :rules="[rules.required, rules.email]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="First Name"
                        placeholder="First Name"
                        v-model="data.first_name"
                        type="text"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="Last Name"
                        placeholder="Last Name"
                        v-model="data.last_name"
                        type="text"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="Phone"
                        placeholder="Your Phone"
                        v-model="data.phone"
                        type="number"
                        :rules="[rules.required, rules.number]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="Password"
                        placeholder="Your Password"
                        v-model="data.password"
                        type="password"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="Confirm Password"
                        placeholder="Password"
                        v-model="data.password_confirmation"
                        type="password"
                        :rules="[rules.required, password_confirmation]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        label="Address"
                        placeholder="Address"
                        v-model="data.address"
                        type="text"
                        :rules="[rules.required]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="6">
                      <v-file-input
                        label="Profile Picture"
                        prepend-icon="mdi-camera"
                        accept="image/png, image/jpeg, image/bmp"
                        @change="onFilePicked"
                        :rules="[rules.required]"
                      ></v-file-input>
                    </v-col>
                  </v-row>
                  <div class="d-flex justify-center">
                    <v-btn class="primary-bg" type="submit">
                      Register
                    </v-btn>
                  </div>
                </v-container>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/util/util.js";
export default {
  name: "Register",
  data: function() {
    return {
      rules: util.validation,
      password_confirmation: (value) =>
        value == this.data.password || "Passwords do not match.",
      data: {
        password: "",
        profile_picture: "",
      },
    };
  },
  methods: {
    onFilePicked(file) {
      if (file) {
        let formData = new FormData();
        formData.append("file", file);
        console.log(formData);
        util
          .http({
            headers: {
              "Content-Type": "multipart/form-data",
            },
            url: "user-detail/file-upload",
            method: "post",
            data: formData,
          })
          .then((res) => {
            if (res.data.status == 1) {
              this.data.profile_picture = res.data.data[0].url;
            } else {
              util.notify(0, res.data.errors[0]);
            }
          });
      }
    },
    onSubmit() {
      if (this.$refs.form.validate()) {
        util
          .http({
            url: "register",
            method: "post",
            data: this.data,
          })
          .then((res) => {
            if (res.data.status == 1) {
              util.loginUsingToken(res.data.data.access_token.access_token);
              util.notify(1, "Login Successful");
              util.fetchPermissions.call(this);
              this.$router.push({ name: "dashboard" });
            } else {
              util.notify(0, res.data.errors[0]);
            }
          });
      }
    },
    // checkABN() {
    //   let abn = this.data.abn;
    //   if (abn.length == 11) {
    //     util
    //       .http({
    //         url: "check-abn",
    //         method: "post",
    //         data: this.data,
    //       })
    //       .then((res) => {
    //         if (res.data.status == 1) {
    //           console.log(res.data.data);
    //           if (res.data.data.AbnStatus == "Active") {
    //             this.data.company_name = res.data.data.EntityName;
    //           } else {
    //             util.notify(0, "Invalid Abn number");
    //             this.data.company_name = "";
    //           }
    //         } else {
    //           util.notify(0, res.data.errors[0]);
    //         }
    //       });
    // }
    // },
  },
};
</script>

<style type="text/css" scoped>
.background-image-section {
  /* background-image: url("../assets/images/login-bg.jpg"); */
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100%;
}
.background-color-section {
  background-color: rgba(251 251 251, 0.65);
  position: absolute;
  inset: 0;
  height: 100%;
  width: 100%;
}

#forgot-pass {
  color: #00b0f0;
  font-family: "Raleway", sans-serif;
  font-size: 10pt;
  margin-top: 3px;
  text-align: center;
}
.card-head img {
  height: 70px;
}
#card {
  background-color: rgba(255, 255, 255, 0.65) !important;
  border-radius: 8px;
  box-shadow: 1px 2px 8px rgba(0, 0, 0, 0.65);
  height: auto;
  width: 700px;
}
#card-content {
  padding: 20px 10px;
}
#card-title {
  padding-bottom: 15px;
  /* padding-top: 13px; */
  text-align: center;
}
#signup {
  color: #00b0f0;
  font-family: "Raleway", sans-serif;
  font-size: 10pt;
  margin-top: 10px;
  text-align: center;
}
.term-condition {
  font-size: 10pt;
  text-align: center;
}
#submit-btn {
  background: -webkit-linear-gradient(right, #00b0f0, #00b0f0);
  border: none;
  border-radius: 21px;
  box-shadow: 0px 1px 8px #00b0f0;
  cursor: pointer;
  color: white;
  font-family: "Raleway SemiBold", sans-serif;
  height: 42.3px;
  margin-top: 20px;
  transition: 0.25s;
  width: 100%;
}
#submit-btn:hover {
  box-shadow: 0px 1px 18px #00b0f0;
}
.form {
  align-items: left;
  display: flex;
  flex-direction: column;
}
.form-border {
  background: -webkit-linear-gradient(right, #00b0f0, #00b0f0);
  height: 1px;
  width: 100%;
}
/* for icon insoide input */
.form-outer {
  max-width: 300px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container {
  width: 90%;
  max-width: 1160px;
  margin: auto;
}
.form-input {
  margin: 10px auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
input {
  border: 2px solid #eee;
  border-radius: 30px;
  padding: 5px 50px 5px 50px;
  font-size: 14px;
  outline: 0;
  display: block;
  width: 100%;
}
.icon {
  position: absolute;
  left: 10px;
  top: 0;
  font-size: 20px;
  color: #00b0f0;
  height: 39px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
